import React from 'react'
import Layout from 'src/components/Layout'
import PageHeader from 'src/components/PageHeader'
import { t } from 'src/i18n/translation/t'
import { PageContext } from 'src/page/types'
import ReferencesGrid from 'src/components/ReferencesGrid'
import { graphql } from 'gatsby'
import {
    unwrapReferenceFromQueryAndSetLangPath,
    WrappedReferenceQueryResult
} from 'src/model/referenceQuery'
import Companies from 'src/components/Companies'
import { Theme } from 'src/style/Theme'
import { css } from 'src/style/css'
import Container from 'src/components/Container'
import {
    unwrapCompanyFromQuery,
    WrappedCompanyQueryResult
} from 'src/model/companyQuery'
import CallToAction from 'src/components/CallToAction'

const Quote = () => (
    <Container
        css={(th: Theme) => css`
            font-weight: ${th.fontWeight.bold};
            font-size: ${th.fontSize.lg};
            text-align: center;
            color: ${th.colors.darkGray};
        `}
    >
        <span>
            &quot;EVERYTHING IS DESIGNED, BUT SOME THINGS ARE DESIGNED
            WELL.&quot;
        </span>
    </Container>
)

type ReferencesPageProps = {
    data: {
        references: WrappedReferenceQueryResult
        companies: WrappedCompanyQueryResult
    }
    pageContext: PageContext
}

const ReferencesPage = ({ pageContext, data }: ReferencesPageProps) => {
    const { lang, msgs } = pageContext
    const allReferences = unwrapReferenceFromQueryAndSetLangPath(
        data.references,
        lang
    )
    const allCompanies = unwrapCompanyFromQuery(data.companies)
    return (
        <Layout pageCtx={pageContext}>
            <PageHeader>{t(msgs.references.title)}</PageHeader>
            <ReferencesGrid
                items={allReferences}
                moreInfo={t(msgs.references.moreInfo)}
            />
            <div
                css={(th: Theme) => css`
                    background: ${th.colors.lightestGray};
                `}
            >
                <Quote />
            </div>
            <Companies items={allCompanies} />
            <CallToAction linkText={t(msgs.homePage.callToAction.linkText)} />
        </Layout>
    )
}
export default ReferencesPage

export const pageQuery = graphql`
    query ReferencePageData {
        references: referenceOrderJson {
            ...References
        }
        companies: companyOrderJson {
            ...Companies
        }
    }
`
