import React from 'react'
import Reference from 'src/components/Reference'
import { ReferenceQueryResult } from 'src/model/referenceQuery'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'

type ReferencesGridProps = {
    items: ReferenceQueryResult[]
    moreInfo: string
}

const ReferencesGrid = ({ items, moreInfo }: ReferencesGridProps) => (
    <div
        css={(th: Theme) => css`
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @media (max-width: ${th.mediaQuery.lg}) {
                grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
            }
            @media (max-width: ${th.mediaQuery.md}) {
                grid-template-columns: repeat(auto-fill, minmax(18em, 1fr));
            }
        `}
    >
        {items.map((reference, i) => (
            <Reference
                key={`reference-${i}`}
                moreInfo={moreInfo}
                item={reference}
            />
        ))}
    </div>
)

export default ReferencesGrid
