import { graphql } from 'gatsby'
import { ImageSrc } from 'src/components/Image'
import { ReferenceInfo } from 'src/model/referenceType'
import { Lang } from 'src/i18n/lang/types'
import { langPath } from 'src/i18n/langPath'

export type ReferenceQueryResult = ReferenceInfo & {
    images: ImageSrc[]
    imageThumb: ImageSrc
}

export type WrappedReferenceQueryResult = {
    references: {
        id: ReferenceQueryResult
    }[]
}

export const unwrapReferenceFromQueryAndSetLangPath = (
    r: WrappedReferenceQueryResult,
    lang: Lang
): ReferenceQueryResult[] =>
    r.references.map((i) => {
        const ref = i.id
        ref.path = langPath(lang, ref.path)
        return ref
    })
export const referenceFragment = graphql`
    fragment References on ReferenceOrderJson {
        references {
            id {
                title {
                    sk
                    en
                    de
                }
                images {
                    ...SrcImages
                }
                imageThumb {
                    ...SrcImages
                }
                type {
                    sk
                    en
                    de
                }
                descriptions {
                    sk
                    en
                    de
                }
                path
                about {
                    sk
                    en
                    de
                }
            }
        }
    }
`
