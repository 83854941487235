import { ImageSrc } from 'src/components/Image'
import { graphql } from 'gatsby'
import { CompanyInfo } from 'src/model/company'

export type CompanyQueryResult = CompanyInfo & {
    image: ImageSrc
}

export type WrappedCompanyQueryResult = {
    companies: {
        id: CompanyQueryResult
    }[]
}

export const unwrapCompanyFromQuery = (
    r: WrappedCompanyQueryResult
): CompanyQueryResult[] => r.companies.map((i) => i.id)

export const companyFragment = graphql`
    fragment Companies on CompanyOrderJson {
        companies {
            id {
                id
                link
                title
                image {
                    ...SrcImages_noBlur
                }
            }
        }
    }
`
