import { getContext } from 'src/components/MyContext'
import { LangDictionary } from 'src/i18n/translation/types'

export type TData = (data: LangDictionary) => string

export const useTranslateData = (): TData => {
    const ctx = getContext()

    return (data: LangDictionary) => ctx.tData(data)
}
