import React, { ReactNode, useEffect, useState } from 'react'
import Container from 'src/components/Container'
import Image, { ImageSrc } from 'src/components/Image'
import Header from 'src/components/Header'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { isContactPage, isReferencesPage } from 'src/navigation/pages'
import ClutchWidget from 'src/components/ClutchWidget'

type PageHeader = {
    children: ReactNode
    subtitle?: string
    image?: ImageSrc
}

const fontColor = (th: Theme, image: PageHeader['image']) =>
    image ? th.colors.white : th.colors.secondary

const PageHeader = ({ children: title, subtitle, image }: PageHeader) => {
    const [isReferences, setIsReferences] = useState(false)
    const [isContact, setIsContact] = useState(false)

    useEffect(() => {
        if (isReferencesPage(window.location.pathname)) {
            setIsReferences(true)
        }
    }, [])
    useEffect(() => {
        if (isContactPage(window.location.pathname)) {
            setIsContact(true)
        }
    }, [])
    return (
        <div
            css={(th: Theme) => css`
                background-color: ${th.colors.lightestGray};
                position: relative;
                overflow: hidden;
                height: 150px;
                color: ${fontColor(th, image)};
                @media (max-width: ${th.mediaQuery.md}) {
                    height: ${isReferences || isContact ? '200px' : '150px'};
                }
            `}
        >
            {image && (
                <Image
                    src={image}
                    alt="page-header"
                    css={css`
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    `}
                />
            )}
            <Container
                css={(th: Theme) => css`
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    align-items: center;
                    @media (max-width: ${th.mediaQuery.md}) {
                        justify-content: center;
                    }
                `}
            >
                <div
                    css={(th: Theme) => css`
                        display: flex;
                        flex-direction: column;
                        @media (min-width: ${th.mediaQuery.md}) {
                            margin-bottom: 0 !important;
                        }
                    `}
                >
                    <Header uppercase size={2}>
                        {title}
                    </Header>
                    {subtitle && (
                        <span
                            css={(th: Theme) => css`
                                color: ${fontColor(th, image)};
                                font-size: ${th.fontSize.md};
                                font-weight: ${th.fontWeight.thin};
                                margin-top: ${th.spacing.sm};
                                line-height: 1.5;
                            `}
                        >
                            {subtitle}
                        </span>
                    )}
                </div>
                {isReferences && <ClutchWidget />}
            </Container>
        </div>
    )
}

export default PageHeader
