import React, { useState } from 'react'
import { animated } from 'react-spring'
import Image from 'src/components/Image'
import useHoverSpring from 'src/hooks/useHoverSpring'
import { ReferenceQueryResult } from 'src/model/referenceQuery'
import { css } from 'src/style/css'
import { Theme } from 'src/style/Theme'
import { useTranslateData } from 'src/i18n/useTranslateData'
import { Link } from 'src/components/Link'

const animationDuration = 300

type ReferenceProps = {
    item: ReferenceQueryResult
    moreInfo: string
}
const Reference = ({ item, moreInfo }: ReferenceProps) => {
    const { title, type, imageThumb, images, path } = item
    const tData = useTranslateData()
    const [hovering, setHovering] = useState(false)
    const hoverStateText = useHoverSpring({
        hovering,
        duration: animationDuration,
        values: {
            from: { transform: 'translate(0, 0%)' },
            to: { transform: 'translate(0, -100%)' }
        }
    })
    const hoverStateImage = useHoverSpring({
        hovering,
        duration: animationDuration,
        values: {
            from: { transform: 'translate(0, 0%)', filter: 'brightness(100%)' },
            to: { transform: 'translate(0, -15%)', filter: 'brightness(70%)' }
        }
    })

    return (
        <Link
            to={path}
            aria-label={tData(title)}
            css={(th: Theme) => css`
                display: inline-block;
                position: relative;
                overflow: hidden;
                height: 450px;
                @media (max-width: ${th.mediaQuery.xl}) {
                    height: 300px;
                }
                @media (max-width: ${th.mediaQuery.lg}) {
                    height: 240px;
                }
                @media (max-width: ${th.mediaQuery.md}) {
                    height: 260px;
                }
            `}
        >
            {/* Need this div as a hack for hover trigger, since Gatsby Images blocks parent */}
            <div
                css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                `}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
            />
            <animated.div
                css={css`
                    position: relative;
                    transform: translate(0, 0%);
                    filter: brightness(100%);
                    display: flex;
                    height: 100%;
                `}
                style={hoverStateImage}
            >
                <Image src={imageThumb || images[0]} alt={tData(title)} />
            </animated.div>
            <animated.div
                css={(th: Theme) => css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    background: ${th.colors.white};
                    position: absolute;
                    left: 0;
                    width: 100%;
                    padding: ${th.spacing.md};
                    transform: translate(0, 0%);
                `}
                style={hoverStateText}
            >
                <span
                    css={(th: Theme) => css`
                        font-weight: ${th.fontWeight.semiBold};
                        color: ${th.colors.black};
                        padding-bottom: ${th.spacing.sm};
                        font-size: ${th.fontSize.md};
                    `}
                >
                    {tData(title)}
                </span>
                <span
                    css={(th: Theme) => css`
                        font-weight: ${th.fontWeight.thin};
                        color: ${th.colors.darkGray};
                    `}
                >
                    {tData(type) + ' - ' + moreInfo}
                </span>
            </animated.div>
        </Link>
    )
}

export default Reference
