import React from 'react'
import Image from 'src/components/Image'
import { css } from 'src/style/css'
import Container from 'src/components/Container'
import { useTheme } from '@emotion/react'
import { CompanyQueryResult } from 'src/model/companyQuery'
import { Helmet } from 'react-helmet'

const ClutchWidget = () => {
    return (
        <>
            <Helmet>
                <script
                    src="https://widget.clutch.co/static/js/widget.js"
                    key="clutch-script"
                    type="text/javascript"
                    async
                />
            </Helmet>
            <div
                className="clutch-widget"
                data-url="https://widget.clutch.co"
                data-widget-type="3"
                data-height="350"
                data-clutchcompany-id="672299"
                css={css`
                    flex: 1 0 auto;
                `}
            />
        </>
    )
}

type Companies = {
    items: CompanyQueryResult[]
}
/*
 * How styling works: Every item has right and bottom border and their container
 * is moved to right and bottom double the width of border, to hide unwanted borders
 * at sides via overflow hidden. Top container is moved to negative values, so it stays centered.
 */
const borderWidth = 1
const Companies = ({ items }: Companies) => {
    const th = useTheme()
    return (
        <Container>
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    @media (max-width: ${th.mediaQuery.lg}) {
                        flex-direction: column;
                        align-items: center;
                    }
                `}
            >
                <div
                    css={css`
                        position: relative;
                        overflow: hidden;
                        max-width: 100%;
                        left: -${borderWidth * 2}px;
                        top: -${borderWidth * 2}px;
                        flex: 1 1 auto;
                        margin: 0 ${th.spacing.lg} 0 0;
                        @media (max-width: ${th.mediaQuery.lg}) {
                            margin: 0 0 ${th.spacing.lg};
                        }
                    `}
                >
                    <div
                        css={css`
                            display: grid;
                            grid-template-columns: repeat(5, 1fr);
                            @media (max-width: ${th.mediaQuery.md}) {
                                grid-template-columns: repeat(
                                    auto-fill,
                                    minmax(9em, 1fr)
                                );
                            }
                            position: relative;
                            left: ${borderWidth * 2}px;
                            top: ${borderWidth * 2}px;
                        `}
                    >
                        {items.map(({ id, link, image, title }) => {
                            const Comp = ({
                                link,
                                rel,
                                target,
                                ...rest
                            }: any) =>
                                link ? (
                                    <a
                                        {...rest}
                                        href={link}
                                        rel={rel}
                                        target={target}
                                    />
                                ) : (
                                    <div {...rest} />
                                )

                            return (
                                <Comp
                                    link={link}
                                    key={id}
                                    css={css`
                                        border-right: ${borderWidth}px dashed
                                            ${th.colors.gray};
                                        border-bottom: ${borderWidth}px dashed
                                            ${th.colors.gray};
                                        padding: 0 ${th.spacing.sm};
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        min-height: 8rem;
                                        @media (max-width: ${th.mediaQuery
                                                .md}) {
                                            min-height: 11rem;
                                        }
                                    `}
                                    target="_blank"
                                    aria-label={title}
                                    rel="noopener noreferrer"
                                >
                                    <Image src={image} alt={title} />
                                </Comp>
                            )
                        })}
                    </div>
                </div>

                <ClutchWidget />
            </div>
        </Container>
    )
}

export default Companies
